import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Router, RouterLink } from "@angular/router";
import { Location } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { ConfirmDialogComponent } from "src/app/shared/dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PlatformService } from "src/app/core/services/platform.service";
import { firstValueFrom } from "rxjs";
import { UserService } from "@kortobaa-front/authentication";

@Component({
  selector: "app-side-menu",
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule, MatIconModule],
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent {
  @Output() toggleSubMenu: EventEmitter<any> = new EventEmitter();
  @Input() sideNavToggleBtn!: boolean;
  @Input() subMenu!: boolean;
  openFlag: boolean = false;
  routeTitle: string = "";
  route!: any;
  sideMenu = [
    {
      id: "home",
      title: "home",
      route: "/home",
      image: `../../../../assets/images/side-menu/home`,
      hasChildren: false,
      children: [] as any,
    },
    {
      id: "statistics",
      title: "statistics",
      route: "/statistics",
      image: `../../../../assets/images/side-menu/statistics`,
      hasChildren: false,
      children: [] as any,
    },
    {
      id: "digital-library",
      title: "digital-library",
      route: "/digital-library",
      image: `../../../../assets/images/side-menu/digital-library`,
      hasChildren: false,
      children: [] as any,
    },
    {
      id: "initiatives",
      title: "initiatives",
      route: "/initiatives",
      image: `../../../../assets/images/side-menu/initiatives`,
      hasChildren: false,
      children: [] as any,
    },
  ];
  @Output() sideNavToggleBtnRes = new EventEmitter<boolean>();
  secondaryColor = "var(--secondary-color)";
  activeChildRoute!: string;

  constructor(
    private _router: Router,
    location: Location,
    private _dialog: MatDialog,
    private _user: UserService,
    private _snackBar: MatSnackBar,
    // private _firebase: FirebaseService,
    public _platForm: PlatformService,
    public _translate: TranslateService
  ) {
    this._router.events.subscribe((val) => {
      if (location.path() != "") {
        let routing = location.path().split("/");
        this.route = location.path();
        this.activeChildRoute = routing[routing.length - 1];
      }
    });
  }

  //toggle function to open and close sub menu in side nav bar
  toggle(value: boolean, openFlag: boolean, title?: string) {
    if (value && this.routeTitle != title) {
      value = true;
      if (title) this.routeTitle = title;
    } else {
      value = false;
      this.routeTitle = "";
    }
    this.openFlag = value;
    this.subMenu = value;
    this.toggleSubMenu.emit(value);
  }

  // go to pages
  goTo(route: string) {
    // check when side nav is close close sub menu when route
    if (this.sideNavToggleBtn) {
      this.toggle(false, false);
    }
    this._router.navigateByUrl(route);
    this.sideNavToggleBtnRes.emit(false);
  }

  // logout
  async logout() {
    const isLogout: boolean = await firstValueFrom(
      this._dialog
        .open(ConfirmDialogComponent, {
          width: "26.25rem",
          data: {
            title: this._translate.instant("login.logout"),
            content: this._translate.instant("login.are-you-want-logout"),
            action: { accept: "yes", refuse: "cancel", color: this.secondaryColor },
          },
        })
        .afterClosed()
    );

    if (isLogout) {
      this._snackBar
        .open(this._translate.instant("confirm-dialog.logout-message"), "", {
          panelClass: "success-snackbar",
          duration: 1500,
        })
        .afterDismissed()
        .subscribe(() => {
          // this._firebase.logout().subscribe();
          this._user.logout();
          this._router.navigate(["/login"]);
          this._dialog.closeAll();
        });
    }
  }
}
