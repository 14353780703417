import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout/layout.component";
import { PlatformService } from "./core/services/platform.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [CommonModule, LayoutComponent],
  templateUrl: "./app.component.html",
  styles: [],
})
export class AppComponent {
  constructor(private _plateForm: PlatformService) {
    this._plateForm.setLanguage();
  }
}
