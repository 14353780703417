import { Route } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { NotAuthGuard } from "./guards/notAuth.guard";

export const routes: Route[] = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "login",
    pathMatch: "full",
    loadComponent: () => import("../auth/pages/auth-frame/auth-frame.component").then((mod) => mod.AuthFrameComponent),
    data: { current: "login" },
    canActivate: [NotAuthGuard],
  },
  {
    path: "home",
    loadComponent: () => import("../home/home/home.component").then((mod) => mod.HomeComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "statistics",
    loadComponent: () =>
      import("../Features/statistics/pages/statistics-page/statistics-page.component").then(
        (mod) => mod.StatisticsPageComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "digital-library",
    loadComponent: () =>
      import("../Features/digital-library/pages/category-list/category-list.component").then(
        (mod) => mod.CategoryListComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "digital-library/:id/content",
    loadComponent: () =>
      import("../Features/digital-library/pages/content-list/content-list.component").then(
        (mod) => mod.ContentListComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "initiatives",
    loadComponent: () =>
      import("../Features/initiatives/pages/initiatives-list/initiatives-list.component").then(
        (mod) => mod.InitiativesListComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "home" },
];
