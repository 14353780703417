import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class PlatformService {
  public screenWidth: number = window.innerWidth;
  android!: boolean;
  ios!: boolean;
  currentLang: string = "ar";

  constructor(
    private platform: Platform,
    private _translateService: TranslateService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.android = this.platform.ANDROID;
    this.ios = this.platform.IOS;
  }

  getLanguage() {
    // return localStorage.getItem("defaultLanguage") || "ar";
    return "ar";
  }

  // set language
  setLanguage() {
    // this.currentLang = localStorage.getItem("defaultLanguage") || "ar";
    this.currentLang = "ar";
    this._translateService.setDefaultLang(this.currentLang);
    this._translateService.use(this.currentLang);
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = this.currentLang === "ar" ? "rtl" : "ltr";
    htmlTag.lang = this.currentLang === "ar" ? "ar" : "en";
    localStorage.setItem("defaultLanguage", this.currentLang);
    let currentUrl = this.router.url;
    if (currentUrl != "/")
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
  }
}
