import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UserService } from "@kortobaa-front/authentication";

export const AuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const user = inject(UserService);
  if (user.currentUser && localStorage.getItem("Token")) {
    return true;
  } else {
    router.navigateByUrl("/login");
    return false;
  }
};
