import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { UserService } from "@kortobaa-front/authentication";
import { PlatformService } from "src/app/core/services/platform.service";
import { checkLanguagePipe } from "src/app/shared/pipes/checkLanguage.pipe";
import { NotifictionNumberPipe } from "src/app/shared/pipes/notifiction-number.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { AuthService } from "src/app/auth/services/auth.service";
import { LanguageIconComponent } from "../../../shared/components/language-icon/language-icon.component";

@Component({
  selector: "app-header",
  standalone: true,
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    RouterLink,
    checkLanguagePipe,
    NotifictionNumberPipe,
    MatMenuModule,
    LanguageIconComponent,
  ],
})
export class HeaderComponent {
  @Input() route: any = [];
  @Input() sideNavToggleBtn: boolean = false;
  navFlag: string = "close";
  hasUnseenNotification!: number;
  @Output() sideNavToggleBtnRes = new EventEmitter<boolean>();
  userLogin = !!this.auth.user.currentUser && !!localStorage.getItem("Token") && !!localStorage.getItem("UserProfile");

  constructor(
    private router: Router,
    // private _notification: NotificationService,
    public user: UserService,
    public _platForm: PlatformService,
    public _translate: TranslateService,
    public auth: AuthService
  ) {
    // this._notification.emitCloseDialog(this.navFlag);
    // this._notification.getCloseDialog().subscribe((data) => {
    //   this.navFlag = data;
    // });
  }

  ngOnInit(): void {
    // this.user.checkUserValid().subscribe((data: any) => {
    //   if (data) {
    //     this._notification.getNotificationsCount().subscribe((data: any) => {
    //       this.hasUnseenNotification = data.count;
    //     });
    //   }
    // });
    // this._notification.getUserIsAuthenticated().subscribe((data: any) => {
    //   this._notification.getNotificationsCount().subscribe((data: any) => {
    //     this.hasUnseenNotification = data.count;
    //   });
    // });
    // this._notification.getSeenNotification().subscribe((data: any) => {
    //   this._notification.getNotificationsCount().subscribe((data: any) => {
    //     this.hasUnseenNotification = data.count;
    //   });
    // });
  }

  // redirect to
  redirectTo(route: string) {
    // if (route == 'consultant-data') {
    //   this._certifiedConsultant.emitEvent(false);
    //   // this.router.navigate([]);
    //   return;
    // }
    this.router.navigate([route]);
  }

  // open notification
  openNotification(value: any) {
    // this.navFlag = this.navFlag == "close" ? "open" : "close";
    // this._notification.emitCloseDialog(this.navFlag);
    // if (this.navFlag == "open") {
    //   // this.notifySub$ = this._notify.updateNotificationToSeen().subscribe((data) => {
    //   //   this.hasUnseenNotification = false;
    //   //   this._notify.emitSeenNotification();
    //   // });
    //   this._notification.emitOpenDialog();
    // }
  }
}
