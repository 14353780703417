import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment-timezone";
import { PlatformService } from "src/app/core/services/platform.service";

@Pipe({
  name: "datePipe",
  standalone: true,
})
export class DatePipePipe implements PipeTransform {
  constructor(private _platFormService: PlatformService) {}
  transform(value: any, format: string, ...args: unknown[]): any {
    moment.locale(this._platFormService.getLanguage());
    if (
      moment(new Date(value)).format("YY ") == moment(new Date()).format("YY ") &&
      moment(new Date(value)).format("MMM ") == moment(new Date()).format("MMM ")
    ) {
      if (new Date(value).getDate() == new Date().getDate()) {
        return moment().calendar().split(" ")[0];
      } else {
        return "لاحقاَ";
      }
    }
  }
}
